<template>
  <div
    class="tongyong"
    :style="{ height: clientHeight - 180 + 'px' }"
    style="overflow-y: scroll"
  >
    <div class="search">
      <span>分类：</span><el-input v-model="input" placeholder=""></el-input>
      <span>节目名称：</span
      ><el-input v-model="input2" placeholder="请输入名称"></el-input>
      <img src="../../assets/images/query.png" alt="" style="width: 49px" />
    </div>
    <div class="tables">
      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="bianhao" label="编号" width="100"></el-table-column>
        <el-table-column prop="fenlei" label="分类"></el-table-column>
        <el-table-column prop="name" label="节目名称"></el-table-column>
        <el-table-column prop="time" label="节目时长"></el-table-column>
        <el-table-column prop="uptime" label="上传时间"></el-table-column>
        <el-table-column prop="number" label="播放次数"></el-table-column>
        <el-table-column prop="personal" label="系统操作">
          <template slot-scope="scope">
            <el-button
              class="ti"
              size="mini"
              @click="handleEdit(scope.$index, scope.row)"
              >查看</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="buttons">
      <el-button>添加按钮</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      clientHeight: document.body.clientHeight,
      input: "",
      input2: "",
      tableData: [
        {
          bianhao: "129",
          fenlei: "1489413",
          number: "18478934568",
          name: "王小虎",
          uptime: "2020-03-25",
          time: "2030-03-25",
        },
        {
          bianhao: "129",
          fenlei: "1489413",
          number: "18478934568",
          name: "王小虎",
          uptime: "2020-03-25",
          time: "2030-03-25",
        },
        {
          bianhao: "129",
          fenlei: "1489413",
          number: "18478934568",
          name: "王小虎",
          uptime: "2020-03-25",
          time: "2030-03-25",
        },
        {
          bianhao: "129",
          fenlei: "1489413",
          number: "18478934568",
          name: "王小虎",
          uptime: "2020-03-25",
          time: "2030-03-25",
        },
        {
          bianhao: "129",
          fenlei: "1489413",
          number: "18478934568",
          name: "王小虎",
          uptime: "2020-03-25",
          time: "2030-03-25",
        },
        {
          bianhao: "129",
          fenlei: "1489413",
          number: "18478934568",
          name: "王小虎",
          uptime: "2020-03-25",
          time: "2030-03-25",
        },
        {
          bianhao: "129",
          fenlei: "1489413",
          number: "18478934568",
          name: "王小虎",
          uptime: "2020-03-25",
          time: "2030-03-25",
        },
        {
          bianhao: "129",
          fenlei: "1489413",
          number: "18478934568",
          name: "王小虎",
          uptime: "2020-03-25",
          time: "2030-03-25",
        },
      ],
    };
  },
  mounted() {
    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenHeight = document.body.clientHeight;
        that.clientHeight = window.screenHeight;
      })();
    };
  },
  watch: {
    clientHeight(val) {
      // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
      if (!this.timer) {
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        this.clientHeight = val;
        this.timer = true;
        let that = this;
        setTimeout(function () {
          // 打印screenWidth变化的值
          console.log(that.clientHeight);
          that.timer = false;
        }, 400);
      }
    },
  },
};
</script>

<style lang="less">
.el-input__inner {
  height: 33px;
  line-height: 33px;
}
.el-table th.el-table__cell > .cell {
  font-size: 14px;
  color: #909499;
  font-weight: bold;
}
.el-table td.el-table__cell div {
  color: #606266;
}
.el-table td.el-table__cell div {
  font-size: 14px;
}
.tongyong {
  .search {
    display: flex;
    align-items: center;
    margin-left: 25px;
    margin-top: 30px;
    span {
      display: block;
      width: auto;
      text-align: center;
      color: #909399;
      font-size: 14px;
      font-weight: bold;
    }
    .el-input {
      width: 200px;
      margin-right: 60px;
    }
  }
  // table
  .tables {
    margin-top: 50px;
    margin-right: 40px;
    margin-left: 25px;
    .el-table::before {
      height: 0;
    }
    .el-table {
      td,
      th.is-leaf {
        border: none !important;
      }
      tr th {
        font-size: 19px;
        color: #a8a8a8;
        text-align: center;
      }
      tr td {
        text-align: center;
        font-size: 17px;
        color: #a8a8a8;
      }
      .ti {
        height: 30px;
        border-radius: 15px;
        color: #a8a8a8;
      }
    }
  }
  .buttons {
    margin-right: 80px;
    .el-button {
      float: right;
      width: 130px;
      height: 40px;
      line-height: 40px;
      border-radius: 25px;
      margin-top: 20px;
      margin-right: 20px;
      background: #d78673;
      color: #ffffff;
      font-size: 16px;
      padding: 0px;
    }
  }
}
</style>